import { storyblokEditable } from '@storyblok/react'
import StoryblokComponent from '@components/Storyblok/StoryblokComponent'
import cn from '@utils/helpers/cn'
import { NextSeo } from 'next-seo'
import { appConfig } from '@utils/appConfig'
import { useSelector } from 'react-redux'

const Page = ({ blok }) => {
  const language = useSelector((state) => state.store.language.code)
  const country = useSelector((state) => state.store.country.code)
  const currency = useSelector((state) => state.store.currency.code)
  return (
    <div
      className={cn('cms-page w-full', blok.class)}
      key={blok._uid}
      {...storyblokEditable(blok)}
    >
      <NextSeo
        title={blok?.title ? blok.title : 'GGM Gastro International GmbH'}
        description={blok?.description ? blok.description : ''}
        noindex={
          process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
            ? !(
                appConfig.storeViews[language]?.seo?.index?.country?.includes(
                  country,
                ) &&
                appConfig.storeViews[language]?.seo?.index?.currency?.includes(
                  currency,
                )
              )
            : true
        }
        nofollow={
          process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
            ? !(
                appConfig.storeViews[language]?.seo?.follow?.country?.includes(
                  country,
                ) &&
                appConfig.storeViews[language]?.seo?.follow?.currency?.includes(
                  currency,
                )
              )
            : true
        }
      />
      {blok.blocks
        ?.filter(
          (b) =>
            (b.hasOwnProperty('showBlock') && b.showBlock) ||
            !b.hasOwnProperty('showBlock'),
        )
        .map((nestedBlok, index) => (
          <StoryblokComponent
            index={index}
            blok={nestedBlok}
            key={nestedBlok._uid}
          />
        ))}
    </div>
  )
}

export default Page
